import { Divider, Spin } from 'antd';
import React from 'react';
import loadable from "@loadable/component"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout";
import { LoaderLarge } from '../components/Loaders';
//import ManagementPanel from '../components/management/ManagementPanel'
const ManagementPanel = loadable(() => import('../components/management/ManagementPanel'));

const ManagementPage = withLayout(({ siteData }) => {
    return (
        <>
            <Seo title={"Darwin Academy Management"} siteMetadata={siteData} />
            <ManagementPanel fallback={<LoaderLarge />} />
            <Divider />
        </>
    );
})

export default ManagementPage;