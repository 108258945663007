import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoaderLarge = () => {
    return <div className='d-flex justify-content-center'><Spin tip={"Loading ..."} /></div>;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const LoaderIcon = () => <div className='d-flex justify-content-center'><Spin indicator={antIcon} /></div>

export { LoaderLarge, LoaderIcon };